import { getConfig } from 'shared/config';
import { post } from 'shared/utils/api';
import { getIndividualId } from 'utils/misc';

const baseHost = 'CUSTOM';

export const getListCustomersByIndividualId = async () => {
  const listCustomersByIndividualIdQuery = `query listCustomersByIndividualId($individualIdFilter: String!) {
    listCustomers @filter(filters: [$individualIdFilter, "engagedParty.@referredType=Individual"]) {
       id
      }
  }`;

  const listCustomersVariables = {
    individualIdFilter: `engagedParty.id=${getIndividualId()}`,
  };

  const listCustomersBody = JSON.stringify({
    query: listCustomersByIndividualIdQuery,
    variables: listCustomersVariables,
  });

  return postToGraphQl(listCustomersBody);
};

export const getProductListCustomersId = async (customerId: string) => {
  const productListQuery = `query listProducts($customerId: ID!) {
    listProducts(customerId: $customerId)
      @limit(limit: 100)
      @filter(
        filters: [
          "status=ACTIVE"
        ]
      ) {
      id
      name
      status
      productFamily {
        id
      }
      characteristic {
        id
        name
        value
        businessType
      }
    }
  }`;

  const productListQuerVariables = {
    customerId,
  };

  const productListBody = JSON.stringify({
    query: productListQuery,
    variables: productListQuerVariables,
  });

  return postToGraphQl(productListBody);
};

const postToGraphQl = async (data: string): Promise<any> => {
  const netCrackerHost = getConfig().netcracker?.host;
  const netCrackerGraphQlEndpoint = getConfig().netcracker?.graphQlEndpoint;
  const netCrackerUrl = `${netCrackerHost}${netCrackerGraphQlEndpoint}`;

  return post({
    url: netCrackerUrl,
    baseHost,
    requireAuth: true,
    data,
  });
};
