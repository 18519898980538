import * as Sentry from '@sentry/react';
import React from 'react';

import { getFromLocalStorage, getFromSessionStorage, setInLocalStorage } from 'utils/storage';
import { Environment, Feature } from './types';

type FeatureToggler = {
  /**
   * The name of the feature toggle
   */
  name: Feature;
  /**
   * Whether the feature is enabled by default.
   * @default false
   */
  enabledByDefault?: boolean;
  /**
   * Whether the feature is allowed in production. Some features are internal and should not be
   * enabled in production, ever. This takes precedence over `enabledByDefault`.
   * @default true
   */
  allowedInProduction?: boolean;
};

export const FeatureToggle = ({
  name,
  enabledByDefault = false,
  allowedInProduction = true,
  children,
}: FeatureToggler & {
  /**
   * The children to render if the feature is enabled
   */
  children: React.ReactNode;
}) => {
  const isEnabled = getFeatureToggle({ name, enabledByDefault, allowedInProduction });
  return isEnabled ? <>{children}</> : null;
};

export const getFeatureToggle = ({
  name,
  enabledByDefault = false,
  allowedInProduction = true,
}: FeatureToggler): boolean => {
  if (!allowedInProduction && process.env.NODE_ENV === 'production') {
    return false;
  }

  if (enabledByDefault) {
    return true;
  }

  // feature toggles can be set both in localstorage and sessionStorage. sessionStorage will be used
  // when we are A/B testing a feature, via Adobe Target.
  const valueFromStorage = getFromLocalStorage(name) || getFromSessionStorage(name);
  const isEnabledFromStorage = valueFromStorage === true;

  if (!isEnabledFromStorage) {
    if (process.env.NODE_ENV !== 'production') {
      setInLocalStorage(name, false);
    }

    return false;
  }

  Sentry.setContext(`Feature ${name}`, {
    isEnabled: true,
  });

  Sentry.addBreadcrumb({
    category: 'feature',
    message: `Feature ${name} is enabled`,
    level: 'info',
  });

  return true;
};

export const dateToggle = (date: string): boolean => {
  return new Date(date) < new Date();
};

export const environmentToggle = (environment: Environment): boolean => {
  return process.env.NODE_ENV === environment;
};
