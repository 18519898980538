import * as Sentry from '@sentry/react';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { isBefore, isWithinInterval } from 'date-fns';
import { config } from 'shared/config';
import { ACCESS_TOKEN, IS_MOCK, MS_VERSION, YS_PRO_INSTANCE } from 'shared/constants/cookies';
import { parseJwt } from 'utils/auth';
import { featLoginWithCiamPing } from 'utils/feature-toggle/feat-login-with-ciam-ping';
import { getParameterizedUrl } from 'utils/format';
import { isUsingMock } from 'utils/mock';
import { cookies } from '../storage/cookies';
import { Host, getHost } from './hosts';

export interface RequestConfig extends AxiosRequestConfig {
  url?: string;
  config?: any;
  baseHost?: Host;
  baseURL?: string;
  apiSegment?: string;
  requireAuth?: boolean;
  headers?: any;
  data?: any;
}

const responseStatus = {
  ERROR: 'Error',
  SUCCESS: 'Success',
};

export const request = (
  type: 'get' | 'post' | 'put' | 'delete' | 'patch' = 'get',
  {
    baseHost = 'MICRO',
    baseURL,
    requireAuth = true,
    apiSegment,
    headers = {},
    data = {},
    ...requestConfig
  }: RequestConfig,
): Promise<any> => {
  let withCredentials = true;
  if (!baseURL) baseURL = getHost({ baseHost, apiSegment, url: requestConfig.url });

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json';
  }

  const msVersionFromCookies = cookies.get(MS_VERSION, true);

  if (baseHost === 'MICRO') {
    withCredentials = false;
    headers['api-version'] = msVersionFromCookies || config?.microServices?.version || 1;
    headers['MobileProd'] =
      !import.meta.env.PUBLIC_TEST_USER || cookies.get(YS_PRO_INSTANCE, true) === 'prod';
  }

  if (baseHost === 'MOBILE') {
    withCredentials = false;
    headers['api-version'] = msVersionFromCookies || 1;
    headers['MobileProd'] =
      !import.meta.env.PUBLIC_TEST_USER || cookies.get(YS_PRO_INSTANCE, true) === 'prod';
  }

  // this is for supporting requests in Storybook
  if (cookies.get(IS_MOCK, true)) {
    baseURL = apiSegment
      ? `${import.meta.env.PUBLIC_SANDBOX_URL}/${apiSegment}`
      : import.meta.env.PUBLIC_SANDBOX_URL;
    withCredentials = false;
    requireAuth = false;
    headers = {};
  }

  if (requireAuth && !headers['Authorization'] && !isUsingMock) {
    const accessToken = cookies.get(ACCESS_TOKEN, true);
    if (!accessToken) return Promise.reject(new Error('Unauthorized - missing token'));
    if (accessToken && accessToken !== 'dummyToken') {
      if (featLoginWithCiamPing()) {
        const jwt = parseJwt(accessToken);

        if (!jwt) {
          // clear the token from the cookie, as it's the previous one from the old login flow
          cookies.remove(ACCESS_TOKEN);
          return Promise.reject(new Error('Unauthorized - invalid token'));
        }

        const jwtExpiration = jwt.exp * 1000;
        if (isBefore(new Date(jwtExpiration), new Date())) {
          return Promise.reject(new Error('Unauthorized - token has expired'));
        }
      } else {
        const expiresOn = new URLSearchParams(accessToken).get('ExpiresOn');
        const expiration = expiresOn ? parseInt(expiresOn) : null;
        // TODO: refresh token
        if (!expiration || isBefore(new Date(expiration * 1000), new Date())) {
          return Promise.reject(new Error('Unauthorized - token has expired'));
        }
      }
      headers['Authorization'] = 'Bearer ' + accessToken;
    }
  }

  return Axios.request({
    withCredentials,
    method: type,
    baseURL,
    headers,
    data,
    ...requestConfig,
  })
    .then((res) => res.data)
    .then((res) => {
      if (res.result === responseStatus.ERROR) {
        Sentry.setContext('API result', res);
        throw new Error('API result', { cause: res });
      }
      return res;
    })
    .catch(function ApiErrorHandler(error: AxiosError) {
      Sentry.withScope((scope) => {
        const { url } = requestConfig.url ? requestConfig : { url: '' };

        const parameterizedUrl = getParameterizedUrl(url || '');
        const transaction = `${type.toUpperCase()} ${parameterizedUrl}`;

        scope.setTransactionName(transaction);
        scope.setFingerprint([
          transaction,
          createErrorMessages({ baseMessage: 'request', transaction, error }),
        ]);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          error.message = createErrorMessages({
            baseMessage: 'response',
            transaction,
            error,
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          error.message = createErrorMessages({
            baseMessage: 'request',
            transaction,
            error,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          error.message = createErrorMessages({
            baseMessage: `setup: ${error.message}`,
            transaction,
            error,
          });
        }
        // flag the error as being "scoped" so that we can look for it in `beforeSend`. This is to
        // prevent it being logged to Sentry again when we bubble it up
        scope.setExtra('scoped', true);

        const isBetween2300And2345 = isWithinInterval(new Date(), {
          start: new Date().setHours(23, 0, 0, 0),
          end: new Date().setHours(23, 45, 0, 0),
        });
        if (isBetween2300And2345 && transaction?.includes('GET yousee/selfcare')) {
          // we don't want to log these errors because they are expected during the nightly
          // maintenance window. We can't use the `ignoreErrors` option in Sentry because it
          // doesn't support time based filtering
          return;
        }

        Sentry.captureException(error);
      });

      // still throw the error so that the caller can handle it
      throw error;
    });
};

const createErrorMessages = ({
  baseMessage,
  transaction,
  error,
}: {
  baseMessage: string;
  transaction: string;
  error: AxiosError;
}): string => {
  let message = `API ${baseMessage}`;
  if (error.response) {
    message += ` - status: ${error.response.status},`;
  } else if (error.request) {
    message += ` - status: ${error.request.status},`;
  }
  return `${message} transaction: ${transaction}, message: ${error.message}`;
};

export const errorCodeHandler = (error: any): Promise<number> => {
  return Promise.reject(error);
};

export const getDelegatedLoginURL = () =>
  featLoginWithCiamPing()
    ? `${import.meta.env.PUBLIC_ODIN_URL}/yousee/selfcare/profile/delegated-login-jwt`
    : `${import.meta.env.PUBLIC_ODIN_URL}/yousee/selfcare/profile/delegated-login`;
