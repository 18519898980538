import { Navigation } from '@nuuday/ys-navigation';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import cx from 'classnames';
import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Router, Switch, useLocation } from 'react-router-dom';

import { Alert } from 'components/Alert';
import { handleMitIDErrorCodes } from 'components/Authentication/MitID';
import { ImpersonationBanner } from 'components/Impersonation/ImpersonationBanner';
import { LeftNav } from 'components/navigation/Components/LeftNav/LeftNav';
import { ScrollToTop } from 'components/ScrollToTop';
import { useWebMessenger } from 'hooks/useWebMessenger';
import NotFound from 'pages/NotFound';
import { createPages, SentryRoute } from 'routes';
import { getParameterizedUrl } from 'utils/format';
import { identity } from 'utils/identity';
import { initialize as initializeTracking, useTrackPageView } from 'utils/user-journey';

import { leftNavBtn } from './components/navigation/Components/LeftNav/initLeftNav';
import { createStore } from './redux/create-store';
import { isUserAgent } from './utils/user-agent';

import './styles.scss';

const store = createStore();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2, // retry 2 times instead of the default 3 times
    },
  },
});

const WrapperWithMessenger = () => {
  useWebMessenger();
  return <></>;
};

export const history = createBrowserHistory();

/**
 * This component allows us to access `useLocation` such that we can preserve query params when
 * redirecting
 * */
const AppRoutes = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const location = useLocation();
  useTrackPageView({
    pageName: getParameterizedUrl(location.pathname),
  });

  return (
    <Switch>
      {createPages(location, isLoggedIn)}
      <SentryRoute path='*'>
        <NotFound />
      </SentryRoute>
    </Switch>
  );
};

const AppRoot = () => {
  handleMitIDErrorCodes();
  const [minimalNavigation] = useState<boolean>(location?.pathname?.startsWith('/smartwear'));

  // initialize tracking
  useEffect(() => {
    if (import.meta.env.MODE === 'production') {
      initializeTracking();
    }

    // no-navpanel-left class is neseccary for mq mixin
    // on web it is set in initLeftNav.ts, which is excluded from the mobile app
    if (
      isUserAgent(['youseeSelfcareApp', 'netcrackerApp']) &&
      !document.body.classList.contains('no-navpanel-left')
    ) {
      document.body.classList.add('no-navpanel-left');
    }
  }, []);

  return (
    <main
      data-hj-suppress
      className={cx('ng-root', {
        ['is-mobile-app']: isUserAgent(['youseeSelfcareApp', 'netcrackerApp']),
      })}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <ScrollToTop />
            {!isUserAgent(['youseeSelfcareApp', 'netcrackerApp']) && (
              <Navigation
                leftNavigationElement={<LeftNav />}
                leftHeaderButtonOnClick={() => leftNavBtn?.toggle()}
                rightHeaderButtonOnClick={() => leftNavBtn?.close()}
                minimalNavigation={minimalNavigation}
              />
            )}
            <ImpersonationBanner />
            <Alert />
            <WrapperWithMessenger />
            <ErrorBoundary>
              <AppRoutes isLoggedIn={!!identity.isLoggedIn} />
            </ErrorBoundary>
            {process.env.NODE_ENV === 'development' ? (
              <ReactQueryDevtools initialIsOpen={false} />
            ) : null}
          </Router>
        </QueryClientProvider>
      </ReduxProvider>
      <div id='virtual-assistant'></div>
    </main>
  );
};

export default AppRoot;
