import { post } from 'shared/utils/api';
import { featLoginWithCiamPing } from 'utils/feature-toggle/feat-login-with-ciam-ping';
import { MITID_BASE_URL } from '../labels';

export const getMitIDAccessToken = (code, codeVerifier) =>
  post({
    url: `/token`,
    data: {
      authorizationCode: code,
      codeVerifier: codeVerifier,
    },
    baseURL: MITID_BASE_URL,
    baseHost: 'CUSTOM',
  })
    .then((data) => data?.access_token)
    .catch(() => {
      return;
    });

export const getMitIDExtendedToken = (subscriptionId, params) =>
  post({
    url: featLoginWithCiamPing()
      ? `/yousee/selfcare/subscriptions/${subscriptionId}/usage/ciam/token`
      : `/yousee/selfcare/subscriptions/${subscriptionId}/usage/token`,
    baseHost: 'ODIN',
    data: {
      token: params.token,
    },
  }).then((data) => data);
