import { BillingAccount } from 'interfaces/ApiTypeAliases';
import { BILLING_STATEMENTS } from 'utils/constants/Redux';

const initialState = { statements: null };

export function billingStatements(
  state = initialState,
  action: {
    type: string;
    data: { statements: BillingAccount[] };
  },
) {
  switch (action.type) {
    case BILLING_STATEMENTS.FETCHED: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
}
