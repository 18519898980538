import { Box, Link, Typography } from '@mui/material';
import { colors } from '@nuuday/yousee-dna/generated/colors';
import { ArrowRight } from '@nuuday/yousee-dna/generated/icons/general';
import React from 'react';
import { labels } from './labels';
import { LadyInChairError } from './svg/LadyInChairError';

interface LinkBoxItem {
  item: {
    title: string;
    href: string;
    icon: React.ReactNode;
  };
}

export default function ErrorUI({
  variant = 'default',
  customTitle,
  customDescription,
}: {
  variant?: 'in card' | 'default';
  customTitle?: string;
  customDescription?: string;
}) {
  return (
    <Box
      sx={{
        m: variant === 'default' ? 'auto' : 'initial',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: variant === 'default' ? '696px' : 'initial',
        bgcolor: colors.White,
        py: variant === 'default' ? '200px' : 'initial',
      }}>
      <Typography variant='h1' sx={{ textAlign: 'center' }}>
        {customTitle ?? labels.title}
      </Typography>
      <Typography variant='articleLight' sx={{ my: 4, textAlign: 'center' }}>
        {customDescription || labels.description}
      </Typography>
      <LadyInChairError />
      <Box
        sx={{
          '&>:first-of-type': { borderRadius: '8px 8px 0 0' },
          '&>:last-child': { borderRadius: '0 0 8px 8px' },
          'width': variant === 'default' ? { xs: '100%', sm: '400px' } : '100%',
          'mt': 5,
        }}>
        {labels.linkBoxes.map((item, index) => (
          <LinkBox key={index} item={item} />
        ))}
      </Box>
    </Box>
  );
}

const LinkBox = ({ item }: LinkBoxItem) => {
  return (
    <Box
      sx={{
        p: 4,
        mt: 1,
        bgcolor: colors.Mint25,
      }}>
      <Link href={item.href} underline='none' sx={{ display: 'flex', alignItems: 'center' }}>
        {item.icon}
        <Box sx={{ display: 'flex', flexGrow: 1, ml: 3 }}>
          <Typography variant='articleLight'>{item.title}</Typography>
        </Box>
        <ArrowRight width={40} height={40} color={colors.Grey1} />
      </Link>
    </Box>
  );
};
