import React from 'react';
import loadable from 'utils/loadable';

export const pages = {
  '/cookie-information': {
    component: loadable(() => import('pages/CookieInformation')),
    path: () => '/cookie-information',
    requiresLogin: false,
  },
  '/tv/:subscriptionId': {
    component: loadable(() => import('pages/TV')),
    path: (id) => `/tv/${id}`,
    requiresLogin: true,
  },
  '/smartwear/:type/order': {
    component: loadable(() => import('pages/Mobile/PageSmartWear')),
    path: (type: 'ios' | 'android') => `/smartwear/${type}/order`,
    requiresLogin: true,
  },
  '/smartwear/:type/manage': {
    component: loadable(() => import('pages/Mobile/PageSmartWear/Manage')),
    path: (type: 'ios' | 'android') => `/smartwear/${type}/manage`,
    requiresLogin: false,
  },
  '/regninger': {
    component: loadable(() => import('pages/Billing')),
    path: () => '/regninger',
    requiresLogin: true,
  },
  '/bredbaand/wifi-booster/redirect': {
    component: loadable(() => import('pages/Broadband/WiFiBoosterRedirect')),
    path: () => '/bredbaand/wifi-booster/redirect',
    requiresLogin: true,
  },
  '/:type(mobil|mobiltbredbaand)/:subscriptionId/abonnement': {
    component: loadable(() => import('pages/Mobile/PageSubscriptionDetails')),
    path: (type: 'mobil' | 'mobiltbredbaand', id) => `/${type}/${id}/abonnement`,
    requiresLogin: true,
  },
  '/:type(mobil|mobiltbredbaand)/:subscriptionId/forbrug': {
    component: loadable(() => import('pages/Mobile/PageUsageSpecification')),
    path: (type: 'mobil' | 'mobiltbredbaand', id) => `/${type}/${id}/forbrug`,
    requiresLogin: true,
  },
  '/:type(mobil|mobiltbredbaand)/:subscriptionId/rejse': {
    component: loadable(() => import('pages/Mobile/PageTravel')),
    path: (type: 'mobil' | 'mobiltbredbaand', id) => `/${type}/${id}/rejse`,
    requiresLogin: true,
  },
  '/:type(mobil|mobiltbredbaand)/:subscriptionId/tilkob': {
    component: loadable(() => import('pages/Mobile/PageSubscriptionAddons')),
    path: (type: 'mobil' | 'mobiltbredbaand', id) => `/${type}/${id}/tilkob`,
    requiresLogin: true,
  },
  '/:type(mobil|mobiltbredbaand)/:subscriptionId/indstillinger': {
    component: loadable(() => import('pages/Mobile/PageSubscriptionSettings')),
    path: (type: 'mobil' | 'mobiltbredbaand', id) => `/${type}/${id}/indstillinger`,
    requiresLogin: true,
  },
  '/:type(mobil|mobiltbredbaand)/:subscriptionId': {
    component: loadable(() => import('pages/Mobile')),
    path: (type: 'mobil' | 'mobiltbredbaand', id) => `/${type}/${id}`,
    requiresLogin: true,
  },
  '/email': {
    component: loadable(() => import('pages/Email')),
    path: () => '/email',
    requiresLogin: true,
  },
  '/bredbaand/:subscriptionId': {
    component: loadable(() => import('pages/Broadband')),
    path: (id) => `/bredbaand/${id}`,
    requiresLogin: true,
  },
  '/fastnet/:subscriptionId': {
    component: loadable(() => import('pages/Landline')),
    path: (id) => `/fastnet/${id}`,
    requiresLogin: true,
  },
  '/fastnet/:subscriptionId/forbrug': {
    component: loadable(() => import('pages/Landline/UsageSpecification')),
    path: (id) => `/fastnet/${id}/forbrug`,
    requiresLogin: true,
  },
  '/indbetaling/find-regning': {
    component: loadable(() => import('pages/PublicInvoice')),
    path: () => '/indbetaling/find-regning',
    requiresLogin: false,
  },
  '/indbetaling/se-regning': {
    component: loadable(() => import('pages/PublicInvoice/Details')),
    path: () => '/indbetaling/se-regning',
    requiresLogin: false,
  },
  '/udbetaling/find-kreditnota': {
    component: loadable(() => import('pages/PublicCreditNote')),
    path: () => '/udbetaling/find-kreditnota',
    requiresLogin: false,
  },
  '/udbetaling/se-kreditnota': {
    component: loadable(() => import('pages/PublicCreditNote/Details')),
    path: () => '/udbetaling/se-kreditnota',
    requiresLogin: false,
  },
  '/indstillinger': {
    component: loadable(async () => {
      const { SettingsPage } = await import('pages/Settings');

      // eslint-disable-next-line react/display-name
      return () => <SettingsPage />;
    }),
    path: () => '/indstillinger',
    requiresLogin: true,
  },
  '/betalingsmetode/:id/skift-interval/:interval/success': {
    component: loadable(async () => {
      const { PaymentIntervalSuccessStepPage } = await import(
        'pages/PaymentMethod/ChangePaymentIntervalFlow/SuccessStep'
      );

      // eslint-disable-next-line react/display-name
      return () => <PaymentIntervalSuccessStepPage />;
    }),
    path: (id, interval) => `/betalingsmetode/${id}/skift-interval/${interval}/success`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id/skift-interval/:interval': {
    component: loadable(async () => {
      const { PaymentIntervalConsentStepPage } = await import(
        'pages/PaymentMethod/ChangePaymentIntervalFlow/ConsentStep'
      );

      // eslint-disable-next-line react/display-name
      return () => <PaymentIntervalConsentStepPage />;
    }),
    path: (id, interval) => `/betalingsmetode/${id}/skift-interval/${interval}`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id/receipt': {
    component: loadable(async () => {
      const { ChangePaymentMethodReceiptStepPage } = await import(
        'pages/PaymentMethod/ChangePaymentMethodFlow/Receipt'
      );

      // eslint-disable-next-line react/display-name
      return () => <ChangePaymentMethodReceiptStepPage />;
    }),
    path: (id) => `/betalingsmetode/${id}/receipt`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id/betalingsservice/accepted': {
    component: loadable(
      () => import('pages/PaymentMethod/ChangePaymentMethodFlow/SwitchToDirectDebit/contact'),
    ),
    path: (id) => `/betalingsmetode/${id}/mobilepay/accepted`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id/betalingsservice': {
    component: loadable(
      () => import('pages/PaymentMethod/ChangePaymentMethodFlow/SwitchToDirectDebit/terms'),
    ),
    path: (id) => `/betalingsmetode/${id}/betalingsservice`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id/kortbetaling/accepted': {
    component: loadable(
      () => import('pages/PaymentMethod/ChangePaymentMethodFlow/SwitchToACP/contact'),
    ),
    path: (id, invoiceId) =>
      invoiceId
        ? `/betalingsmetode/${id}/kortbetaling/accepted?invoiceId=${invoiceId}`
        : `/betalingsmetode/${id}/kortbetaling/accepted`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id/kortbetaling': {
    component: loadable(
      () => import('pages/PaymentMethod/ChangePaymentMethodFlow/SwitchToACP/terms'),
    ),
    path: (id, invoiceId) =>
      invoiceId
        ? `/betalingsmetode/${id}/kortbetaling?invoiceId=${invoiceId}`
        : `/betalingsmetode/${id}/kortbetaling/`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id/mobilepay/accepted': {
    component: loadable(
      () => import('pages/PaymentMethod/ChangePaymentMethodFlow/SwitchToMobilePay/contact'),
    ),
    path: (id) => `/betalingsmetode/${id}/mobilepay/accepted`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id/mobilepay': {
    component: loadable(
      () => import('pages/PaymentMethod/ChangePaymentMethodFlow/SwitchToMobilePay/terms'),
    ),
    path: (id) => `/betalingsmetode/${id}/mobilepay`,
    requiresLogin: true,
  },
  '/betalingsmetode/:id': {
    component: loadable(() => import('pages/PaymentMethod/Details')),
    path: (id) => `/betalingsmetode/${id}`,
    requiresLogin: true,
  },
  '/betalingsmetode': {
    component: loadable(() => import('pages/PaymentMethod')),
    path: () => `/betalingsmetode`,
    requiresLogin: true,
  },
  '/forny-akb': {
    component: loadable(async () => {
      const { PublicMissingPaymentMethod } = await import('pages/PublicMissingPaymentMethod');

      // eslint-disable-next-line react/display-name
      return () => <PublicMissingPaymentMethod />;
    }),
    path: () => '/forny-akb',
    requiresLogin: false,
  },
  '/overblik': {
    component: loadable(() => import('pages/Overview')),
    path: () => '/overblik',
    requiresLogin: true,
  },
  '/401': {
    component: loadable(() => import('pages/NotAuthorized')),
    path: () => '/401',
    requiresLogin: false,
  },
  ...(process.env.NODE_ENV !== 'production' && {
    '/tilknyt': {
      component: loadable(() => import('pages/Association')),
      path: () => '/tilknyt',
      requiresLogin: true,
    },
  }),
  ...(process.env.NODE_ENV !== 'production' && {
    '/fjern': {
      component: loadable(() => import('pages/DeleteAssociation')),
      path: () => '/fjern',
      requiresLogin: true,
    },
  }),
  '/': {
    component: loadable(() => import('pages/Overview')),
    path: () => '/',
    requiresLogin: true,
  },
} as const;
