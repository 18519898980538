import { useEffect, useState } from 'react';

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
} as const;

export const useScreenSize = (
  from: keyof typeof breakpoints,
  to: keyof typeof breakpoints | null = null,
  checkSideBar = true,
) => {
  const sideBarSize = useSideBarSize();
  const [minWidth, setMinWidth] = useState(breakpoints[from] + (checkSideBar ? sideBarSize : 0));
  const [maxWidth, setMaxWidth] = useState(
    to && breakpoints[to] - 1 + (checkSideBar ? sideBarSize : 0),
  );
  const matchesFrom = useMediaQuery(`all and (min-width: ${minWidth}px)`);
  const matchesTo = useMediaQuery(to ? `all and (max-width: ${maxWidth}px)` : '');

  useEffect(() => {
    setMinWidth(breakpoints[from] + (checkSideBar ? sideBarSize : 0));
    if (to) setMaxWidth(breakpoints[to] - 1 + (checkSideBar ? sideBarSize : 0));
  }, [from, sideBarSize, checkSideBar, to]);

  return matchesFrom && (!to || matchesTo);
};

export const useSideBarSize = () => {
  const isLarge = useMediaQuery('all and (min-width: 1200px)');
  const sideBarIsOpen = useSideBarIsOpen();

  return sideBarIsOpen ? (isLarge ? 376 : 300) : 0;
};

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export const useSideBarIsOpen = () => {
  const [isOpen, setIsOpen] = useState(
    window['navheaderButtonSelfcare']?.classList.contains('is-active') || false,
  );

  useEffect(() => {
    const listener = () =>
      setIsOpen(window['navheaderButtonSelfcare']?.classList.contains('is-active') || false);
    window['navheaderButtonSelfcare']?.addEventListener('click', listener);
    return () => window['navheaderButtonSelfcare']?.removeEventListener('click', listener);
  });

  return isOpen;
};
