import { Container } from '@mui/material';
import { Card } from 'components/Card';
import { CardContent } from 'components/Card/CardContent';
import ErrorUI from 'components/ErrorHandling';
import React from 'react';

export default function NotFound() {
  // Not reporting to Sentry for now since we get a shit ton of false-positives because NotFound is rendered but not shown when navigating between MFEs
  // useEffect(() => {
  //   Sentry.captureMessage(`Rendered NotFound - URL pathname: ${window.location.pathname}`);
  // }, []);

  return (
    <Container maxWidth='md'>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <ErrorUI
            variant='in card'
            customDescription='Den side du vil ind på er enten flyttet eller fjernet.'
          />
        </CardContent>
      </Card>
    </Container>
  );
}
