import {
  getFromLocalStorage,
  getFromSessionStorage,
  removeFromLocalStorage,
  setInSessionStorage,
} from 'utils/storage';
import Request from '../lib/request';
import { getUserId } from '../lib/user';

function EmailsPlugin() {
  const domElement = document.querySelector('li[data-type="emails"]');
  if (domElement) {
    const key = getUserId() + '-hasSubscription';
    const hasSubscription = getFromSessionStorage(key);

    if (getFromLocalStorage(key)) {
      // clear legacy implementation
      removeFromLocalStorage(key);
    }

    if (hasSubscription === null || typeof hasSubscription === 'undefined') {
      Request(
        'emailaccess',
        (emailAccess) => {
          setInSessionStorage(getUserId() + '-hasSubscription', emailAccess.hasSubscription);
          HandleEmailItem(emailAccess.hasSubscription, domElement);
        },
        (err) => {
          console.warn('Navigation: emails-plugin - emails/hassubscription failed', err);

          domElement.parentNode?.removeChild(domElement);
        },
      );
    } else {
      HandleEmailItem(hasSubscription, domElement);
    }
  }
}

function HandleEmailItem(hasSubscription, domElement) {
  // when the value comes from API it is a boolean, but from localStorage it is a string
  if (
    (typeof hasSubscription === 'boolean' && !hasSubscription) ||
    (typeof hasSubscription === 'string' && hasSubscription.toLowerCase() === 'false')
  ) {
    domElement.parentNode.removeChild(domElement);
    return;
  }
  domElement.removeAttribute('style');
}

export default EmailsPlugin;
