import * as Sentry from '@sentry/react';
import { NotLoggedIn } from 'pages/NotLoggedIn';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { pages } from './pages';

// Create Custom Sentry Route component
export const SentryRoute = Sentry.withSentryRouting(Route);

export const createPages = (location, isLoggedIn) =>
  Object.entries(pages).map(([path, { component, requiresLogin }], index) => {
    const view = !isLoggedIn && requiresLogin ? NotLoggedIn : component;

    // in cases where the hash has been encoded (to survive the login redirect), we need to decode
    // it back
    const hash = decodeURIComponent(location.pathname).split('#')[1];

    return [
      <SentryRoute key={path + index} path={path} component={view} exact />,
      // Redirect to path without beta for people who might have bookmarked it
      <Redirect
        key={path + index + 'redirect'}
        from={`/beta${path}`}
        // we destruct location to preserve search params
        to={{ ...location, pathname: path }}
        exact
      />,
      ...(hash
        ? [
            <Redirect
              key={path + index + 'redirect2'}
              from={`${path}%23${hash}`}
              to={{ ...location, pathname: path, hash: `#${hash}` }}
              exact
            />,
          ]
        : []),
    ];
  });
