import { PAYMENTMETHOD } from 'utils/constants/Redux';

interface Agreement {
  accountId: number;
  affiliations: any[];
  agreementId: number;
  billingAccountNumber: string;
  creditCard?: {
    cardNumberMasked: string;
    cardNumberPrefix: string;
    cardStatus: string;
    creditCardType: string;
    expirationDate: string;
  };
  externalAgreementId: string;
  frequency: 'Monthly' | 'Quarterly' | 'Unknown';
  payingFor: string[];
  type: string;
}

interface ThirdPartyAgreement {
  accountNumber: number;
  affiliation: string;
  payingFor: string[];
}

interface PaymentMethodData {
  agreements?: Agreement[];
  thirdparty?: ThirdPartyAgreement[];
}

const initialState: PaymentMethodData = {
  agreements: undefined,
  thirdparty: undefined,
};

export function paymentMethod(
  state = initialState,
  action: { type: string; data: PaymentMethodData },
) {
  switch (action.type) {
    case PAYMENTMETHOD.FETCHEDAGREEMENTS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case PAYMENTMETHOD.FETCHEDTHIRDPARTY: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
}
