export const PAYMENTMETHOD = {
  FETCHEDAGREEMENTS: 'PAYMENTMETHOD-FETCHED-AGREEMENTS',
  FETCHEDTHIRDPARTY: 'PAYMENTMETHOD-FETCHED-THIRDPARTY',
  ERROR: 'PAYMENTMETHOD-ERROR',
};

export const BILLING_STATEMENTS = {
  FETCHED: 'BILLING_STATEMENTS-FETCHED',
  ERROR: 'BILLING_STATEMENTS-ERROR',
};
