import { getConfig } from 'shared/config';
import { getDelegatedLoginURL } from 'shared/utils/api/request';
import { identity } from '../../../../utils/identity';
import { InitializeSelfcareIcon } from '../../Utils/animations/icon-selfcare';
import { events } from '../../Utils/events';
import NavAccessibility from '../../Utils/lib/nav-accessibility';
import NavButton from '../../Utils/lib/nav-button';
import ChangeCardPlugin from '../../Utils/plugins/change-card-plugin';
import EmailPlugin from '../../Utils/plugins/emails-plugin';
import ShortcutsPlugin from '../../Utils/plugins/shortcuts-plugin';
import UserPlugin from '../../Utils/plugins/user-plugin';

// Hightlight active link in navpanel
const HighlightActiveMenuitem = () => {
  const selfcareNav = document.getElementById('nav-selfcare');
  for (const activeItem of Array.from(
    document.getElementsByClassName('navpanel__item-link is-active') || [],
  ))
    activeItem.classList.remove('is-active');

  const domNavPanelLink = Array.from(
    selfcareNav?.getElementsByClassName('navpanel__item-link') || [],
  );
  const address = location.pathname;
  const topRoute = address.split('/').length > 1 ? `/${address.toLowerCase().split('/')[1]}` : '/';

  for (let i = 0; i < domNavPanelLink.length; i++) {
    const currentNavPanelLink = domNavPanelLink[i] as HTMLAnchorElement;
    if (currentNavPanelLink.search?.includes('yss-leftmenu-userlink')) {
      continue;
    }

    if (
      currentNavPanelLink.origin === location.origin &&
      !currentNavPanelLink.href.endsWith('#') &&
      (topRoute === currentNavPanelLink.pathname ||
        (address.startsWith(currentNavPanelLink.pathname) && currentNavPanelLink.pathname !== '/'))
    ) {
      currentNavPanelLink.classList.add('is-active');
      break;
    }
  }
};

export let leftNavBtn: NavButton | null = null;
export const initLeftNav = () => {
  let currentBtn: NavButton | null = null;
  const navButtons: NavButton[] = [];

  InitializeSelfcareIcon();

  const navAccessibility = new NavAccessibility();

  const domButtonSelfcare = document.getElementById('navheaderButtonSelfcare');
  if (domButtonSelfcare) {
    const { alwaysShowLeftNav } = getConfig().navigation || {};
    const showNav = identity.isLoggedIn || alwaysShowLeftNav;
    // Set the nav button
    leftNavBtn = new NavButton(domButtonSelfcare);
    navButtons.push(leftNavBtn);

    if (showNav) {
      events.on('open', (side) => {
        if (side === 'nav-selfcare') {
          document.body.classList.add('has-navpanel-left');
          document.body.classList.remove('no-navpanel-left');
        }
      });
      events.on('close', (side) => {
        if (side === 'nav-selfcare') {
          document.body.classList.add('no-navpanel-left');
          document.body.classList.remove('has-navpanel-left');
        }
      });
      if (window.innerWidth >= 576) {
        leftNavBtn.open();
      } else {
        document.body.classList.add('no-navpanel-left');
      }

      leftNavBtn.onAfterClick = () => {
        // Loop all cached buttons
        // ... close all other buttons than the one clicked.
        for (let i = 0; i < navButtons.length; i++) {
          const button = navButtons[i];
          // If the current button is not the same as the one clicked
          // ... close it.
          if (button !== leftNavBtn) {
            button.close();
            if (leftNavBtn?.isOpen()) {
              currentBtn = button;
              navAccessibility.able('create');
              addEventListener('keydown', escapeEvent);
            } else {
              navAccessibility.able('destroy');
              removeEventListener('keydown', escapeEvent);
            }
          }
        }
      };

      // Check for logged-in user.
      if (identity.isLoggedIn) {
        // Init plugins for selfcare panel.
        UserPlugin();
        ChangeCardPlugin();
        EmailPlugin();
        ShortcutsPlugin();
      }
    } else {
      leftNavBtn.onClick = () => (location.href = `${getDelegatedLoginURL()}`);
    }
  }

  //Adds esc event to nav. menus, A11Y related
  function escapeEvent(event) {
    if (event.which === 27) {
      event.preventDefault();

      // Close all buttons.
      for (let i = 0; i < navButtons.length; i++) {
        const button = navButtons[i];
        button.close();

        if (button === currentBtn && !button.isOpen()) {
          navAccessibility.able('destroy');
        }
      }
    }
  }

  HighlightActiveMenuitem();
  window.addEventListener('onlocationchange', HighlightActiveMenuitem);
};
