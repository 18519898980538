import { Container } from '@mui/material';
import React, { useEffect } from 'react';

import ErrorUI from 'components/ErrorHandling';

export default function ErrorPage() {
  useEffect(() => {
    document.body.classList.remove('has-navpanel-left');
  }, []);

  return (
    <Container maxWidth='md'>
      <ErrorUI />
    </Container>
  );
}
