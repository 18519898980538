import { useQuery } from '@tanstack/react-query';
import {
  getListCustomersByIndividualId,
  getProductListCustomersId,
} from 'api/netCrackerAPI/netCrackerApi';
import {
  NetCrackerProductCharacteristicType,
  NetCrackerProductType,
  NetCrackerSubscriptionType,
} from 'api/netCrackerAPI/netCrackerApiTypes';
import { useEffect, useState } from 'react';
import { featNetcrackerLive } from 'utils/feature-toggle/feat-netcracker-live';
import { getIndividualId, hasDualStackProducts } from 'utils/misc';
import {
  getAddress,
  getPhoneNumber,
  getProductName,
  getProductType,
  getUrl,
  hasPhoneNumber,
} from './useMyColumbusSubscriptions/useMyColumbusSubscriptionsUtils';

const useMyNetcrackerSubscriptions = () => {
  const isStackDual = hasDualStackProducts() && featNetcrackerLive();
  const individualId = getIndividualId() || 'test';

  const {
    data: customerId,
    isError: customerQueryError,
    isLoading: isCustomerQueryLoading,
  } = useQuery({
    queryKey: ['netCrackerCustomer', isStackDual, individualId],
    queryFn: getListCustomersByIndividualId,
    select: (data) => data?.data?.listCustomers?.[0],
    enabled: isStackDual,
    staleTime: 1000 * 60, // one minute
  });

  const {
    data: subscriptions,
    isError: productsQueryError,
    isLoading: isProductsQueryLoading,
  } = useQuery({
    queryKey: ['netCrackerProducts', customerId?.id],
    queryFn: () => getProductListCustomersId(customerId),
    select: (data) => filterNetCrackerCharacteristics(data),
    enabled: !!customerId,
    staleTime: 1000 * 60, // one minute
  });

  const [netCrackerProductListErrorMessage, setNetCrackerProductListErrorMessage] =
    useState<string>('');

  useEffect(() => {
    setNetCrackerProductListErrorMessage(
      customerQueryError || productsQueryError
        ? 'Vi kunne ikke hente alle dine abonnementer - vi beklager'
        : '',
    );
  }, [customerQueryError, productsQueryError]);

  return {
    netCrackerSubscriptions: subscriptions ? subscriptions : [],
    netCrackerProductListErrorMessage,
    isLoadingNetCracker: isCustomerQueryLoading || (isProductsQueryLoading && !customerQueryError),
  };
};
export default useMyNetcrackerSubscriptions;

const filterNetCrackerCharacteristics = (responseData: {
  data: { listProducts: NetCrackerProductType[] };
}): NetCrackerSubscriptionType[] => {
  if (!responseData?.data?.listProducts) return [];

  const netCrackerProducts: NetCrackerProductType[] = responseData.data.listProducts;
  const netCrackerSubscriptions: NetCrackerSubscriptionType[] = [];

  netCrackerProducts
    .flatMap((product) => product?.characteristic)
    .forEach((characteristic) => {
      if (characteristic) {
        const subscription = createNetcrackerSubscription(characteristic);
        if (subscription) netCrackerSubscriptions.push(subscription);
      }
    });

  return netCrackerSubscriptions;
};

const createNetcrackerSubscription = (
  characteristic: NetCrackerProductCharacteristicType,
): NetCrackerSubscriptionType | null => {
  let netCrackerSubscription: NetCrackerSubscriptionType | null = null;

  enum ProductBusinessType {
    PHONE_NUMBER = 'PHONE_NUMBER',
    SIM_CARD = 'SIM_CARD',
    INTERNATIONAL_PHONE_NUMBER = 'INTERNATIONAL_PHONE_NUMBER',
    INFORMATION_TO_PUBLIC_DIRECTORY = 'INFORMATION_TO_PUBLIC_DIRECTORY',
    SIM_TYPE = 'SIM_TYPE',
    MOBILE_LINE_TYPE = 'MOBILE_LINE_TYPE',
    OTT_TYPE = 'OTT_TYPE',
  }

  enum ProductTypes {
    PHONE_NUMBER = 'Mobile',
  }

  switch (characteristic.businessType) {
    case ProductBusinessType.PHONE_NUMBER:
      return (netCrackerSubscription = {
        ...formatNetCrackerSubscription(characteristic, ProductTypes.PHONE_NUMBER),
        phoneNumber: characteristic.value[0],
      });
    case ProductBusinessType.INFORMATION_TO_PUBLIC_DIRECTORY:
      break;
    case ProductBusinessType.MOBILE_LINE_TYPE:
      break;
    case ProductBusinessType.INTERNATIONAL_PHONE_NUMBER:
      break;
    case ProductBusinessType.OTT_TYPE:
      break;
    case ProductBusinessType.SIM_CARD:
      break;
    case ProductBusinessType.SIM_TYPE:
      break;
    default:
      return null;
  }
  return netCrackerSubscription;
};

const formatNetCrackerSubscription = (
  characteristic: NetCrackerProductCharacteristicType,
  productType: string,
): NetCrackerSubscriptionType => {
  const formattedNetCrackerSubscription: NetCrackerSubscriptionType = {
    isNetCrackerSubscription: true,
    value: characteristic.value[0],
    productType: getProductType(productType),
    subscriptionId: characteristic.id,
    characteristicName: characteristic.name,
    productName: getProductName(productType),
    getAddress: function (): string {
      return getAddress(this.address);
    },
    hasPhoneNumber: function (): boolean {
      return hasPhoneNumber(this);
    },
    getPhoneNumber: function (): string {
      return getPhoneNumber(this, this.value);
    },
    getUrl: function (): string {
      return getUrl([], this.productType, this, this.isNetCrackerSubscription);
    },
  };

  return formattedNetCrackerSubscription;
};
