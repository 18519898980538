import { useMyColumbusSubscriptions } from 'hooks/useMyColumbusSubscriptions';
import useMyNetcrackerSubscriptions from 'hooks/useMyNetCrackerSubscriptions';
import React, { FC } from 'react';
import {
  hasDualStackProducts,
  hasOnlyColumbusProducts,
  hasOnlyNetCrackerProducts,
} from 'utils/misc';
import { LeftNavSubscriptionsError } from './LeftNavSubscriptionsError';
import { LeftNavSubscriptionsLoader } from './LeftNavSubscriptionsLoader';
import { SubscriptionsNavItems } from './SubscriptionsNavItems';

export const LeftNavSubscriptions: FC = () => {
  const isStackDual = hasDualStackProducts();
  const { netCrackerSubscriptions, netCrackerProductListErrorMessage, isLoadingNetCracker } =
    useMyNetcrackerSubscriptions();

  const { columbusSubscriptions, columbusSubscriptionsError, loadingColumbusSubscriptions } =
    useMyColumbusSubscriptions();

  return (
    <div>
      {(loadingColumbusSubscriptions || (isStackDual && isLoadingNetCracker)) && (
        <LeftNavSubscriptionsLoader />
      )}
      {hasDualStackProducts() && (
        <>
          {!loadingColumbusSubscriptions && !isLoadingNetCracker && (
            <div>
              <SubscriptionsNavItems
                subscriptions={[...columbusSubscriptions, ...netCrackerSubscriptions]}
              />
            </div>
          )}
          {(netCrackerProductListErrorMessage || columbusSubscriptionsError) && (
            <LeftNavSubscriptionsError />
          )}
        </>
      )}
      {hasOnlyColumbusProducts() && (
        <>
          {!loadingColumbusSubscriptions && !columbusSubscriptionsError && (
            <div>
              <SubscriptionsNavItems subscriptions={columbusSubscriptions} />
            </div>
          )}
          {columbusSubscriptionsError && <LeftNavSubscriptionsError />}
        </>
      )}
      {hasOnlyNetCrackerProducts() && (
        <>
          {!isLoadingNetCracker && !netCrackerProductListErrorMessage && (
            <div>
              <SubscriptionsNavItems subscriptions={netCrackerSubscriptions} />
            </div>
          )}
          {netCrackerProductListErrorMessage && <LeftNavSubscriptionsError />}
        </>
      )}
    </div>
  );
};
