import { colors } from '@nuuday/yousee-dna/generated/colors';
import { HelpWithCircle, Home, Profile } from '@nuuday/yousee-dna/generated/icons/general';
import React from 'react';

export const labels = {
  title: 'Ups, der er sket en fejl!',
  description: 'Vi har desværre tekniske problemer på denne side i øjeblikket.',
  linkBoxes: [
    {
      title: 'Forsiden',
      href: 'https://yousee.dk',
      icon: <Home width={40} height={40} color={colors.Grey1} />,
    },
    {
      title: 'Hjælpesider',
      href: 'https://yousee.dk/hjaelp',
      icon: <HelpWithCircle width={40} height={40} color={colors.Grey1} />,
    },
    {
      title: 'Mit YouSee',
      href: 'https://ny-selvbetjening.yousee.dk',
      icon: <Profile width={40} height={40} color={colors.Grey1} />,
    },
  ],
};
