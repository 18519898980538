// @ts-nocheck
import { TimelineMax } from 'gsapOld';
import '../gsap/MorphSVGPlugin';

const tl = new TimelineMax({ paused: true });
const duration = 0.15;

export const InitializeSelfcareIcon = () => {
  const easeDefault = window.Power2.easeOut;
  const domPath1 = document.getElementById('svgSelfcareIconPath1');
  const domPath2 = document.getElementById('svgSelfcareIconPath2');

  // Construct timeline
  if (domPath1 && domPath2) {
    tl.to(
      domPath1,
      duration,
      {
        scaleX: 0.9,
        scaleY: 1.2,
        y: 2,
        transformOrigin: '50% 50%',
        ease: easeDefault,
      },
      'start',
    )
      .to(domPath2, 0.25, { morphSVG: '#svgSelfcareMiddle1', ease: easeDefault }, 'start')
      .to(domPath1, duration, {
        scaleX: 1,
        scaleY: 1,
        y: -2,
        ease: easeDefault,
      })
      .to(
        domPath2,
        duration,
        { morphSVG: '#svgSelfcareMiddle2', ease: easeDefault },
        `-=${duration}`,
      )
      .to(domPath1, duration, { y: 3, ease: easeDefault })
      .add('reverseStop')
      .to(domPath1, 0.1, {
        y: 0,
        morphSVG: '#svgSelfcareStageHead',
        ease: easeDefault,
      })
      .to(domPath1, duration, {
        morphSVG: '#svgSelfcareStage1',
        ease: easeDefault,
      })
      .to(
        domPath2,
        duration,
        { morphSVG: '#svgSelfcareStage2', ease: easeDefault },
        `-=${duration}`,
      )
      .to(domPath1, duration, { morphSVG: '#svgSelfcareEnd1', ease: easeDefault }, '-=0')
      .to(
        domPath2,
        duration,
        { morphSVG: '#svgSelfcareEnd2', ease: easeDefault },
        `-=${duration}`,
      );
  }
};

// Play timeline
function AnimatedSelfcareIcon(playReversed) {
  if (playReversed === false) {
    tl.tweenFromTo(0, tl.duration());
  }

  if (playReversed === true) {
    tl.tweenTo('reverseStop');
  }
}

export default AnimatedSelfcareIcon;
