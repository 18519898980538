import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, createStore as createReduxStore } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';

const middleware = [thunk];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export function createStore() {
  return createReduxStore(reducers, compose(applyMiddleware(...middleware), sentryReduxEnhancer));
}
